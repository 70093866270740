<button
  type="button"
  [class]="buttonClasses"
  (click)="handleClick()"
  [disabled]="loader || disabled"
  [ngStyle]="{
    cursor: disabled ? 'not-allowed' : 'pointer',
    color: disabled ? 'white' : 'auto'
  }"
>
  <ng-content *ngIf="!loader"></ng-content>
  <p-loader-circle *ngIf="loader"></p-loader-circle>
</button>
